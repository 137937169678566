import React, { useEffect, useState } from 'react';
import { Form, Select, Rate, Button, Row, Col, Input, Modal, Result, Radio } from 'antd';
import { useDispatch } from 'react-redux';
import { setTempToken } from '../../../features/auth/authSlice';
import { getClientSecret, getClient_id } from '../../../utils/functions';
import axiosHIT from 'axios'
import { apiHitWithTempToken } from '../../../service/authService';
import { LikeOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './rating.css'
import { veryunhappy,unhappy,happy,veryhappy } from '../../../utils/images';

const { Option } = Select;

const RatingForm = () => {
    const location = useLocation();
const queryParams = new URLSearchParams(location.search);

const patientId = queryParams.get('patientId');
const doc_ID = queryParams.get('doc_ID');
const clinic_ID = queryParams.get('clinic_ID');
const APPID = queryParams.get('APPID');

console.log(`Patient ID: ${patientId}`);
console.log(`Doctor ID: ${doc_ID}`);
console.log(`Clinic ID: ${clinic_ID}`);
console.log(`APPID: ${APPID}`);

  const [appointment, setAppointment] = useState('');
  const [care, setCare] = useState(0);
  const [frontDesk, setFrontDesk] = useState(0);
  const [isNA, setIsNA] = useState(false);

  const [recommendation, setRecommendation] = useState(10);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState(''); // Optional field for additional comments
  const [likelyComment, setLikelyComment] = useState(''); // Optional field for additional comments

  const [formSubmitted, setFormSubmitted] = useState(false); // New state variable
  const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false); // New state variable
  
  const handleSubmit = (event) => {
    // Handle form submission here, including feedback if provided
    // console.log('Appointment:', appointment);
    // console.log('Care Rating:', care);
    // console.log('Front Desk Rating:', frontDesk);
    // console.log('Recommendation Rating:', recommendation);
    if(recommendation <= 2 && likelyComment == ""){
      Modal.error({
        title: '',
        content: 'Please provide additional comments about your recommendation',
      });
      return;
    }
    hitapi()
  };
  
  
  const hitapi = async()  => {
    setIsLoading(true);
    try {
      const FD = new FormData();
      FD.append("action", "submit_provider_ratting_fromemail");
      FD.append("rate_app_manner", appointment);
      FD.append("rate_care_provider", care);
      FD.append("rate_front_desk",isNA == true ? 4: frontDesk);
      FD.append("rate_recomendation", recommendation);
      FD.append("patientId", patientId);
      FD.append("doc_ID", doc_ID);
      FD.append("clinic_ID", clinic_ID);
      FD.append("APPID", APPID);
      FD.append("comment", feedback);
      FD.append("rate_recomendation_comment", recommendation <= 2 ?likelyComment:"");

     
      
      let res = await apiHitWithTempToken(FD);
      setIsLoading(false);
      if (res.data.status === 200) {

        setFormSubmitted(true);

      }else if (res.data.status === 400){
        setFormAlreadySubmitted(true)
      }
    } catch (error) {
    
    }
    setIsLoading(false);
  }

  const authStep1=(code)=>{
    const formdata = new FormData();
    formdata.append("client_id", getClient_id());
    formdata.append("client_secret", getClientSecret());
    formdata.append("grant_type",code?'authorization_code': "client_credentials");
    if(code){
      formdata.append("code",code);
    }
  
    return axiosHIT({
      url: `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/Token.php`,
      method: "post",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        
      },
    });
  }
  const generateTempToken = async () => {
    try {
        const res = await authStep1();
        if (res.status === 200) {
            dispatch(setTempToken(res?.data?.access_token));
           
        }

    } catch (error) {}
}

const images = [veryunhappy,unhappy,happy,veryhappy];


const EmojiRate = ({ value, onChange,type }) => {
  return (
    <div className="image-container">
      {images.map((image, index) => (
        <button
          key={index}
          className={`image-button ${value > index ? 'selected' : ''}`}
          onClick={() => {
            if(type == 1){
              setIsNA(false)
            }
            onChange(index + 1)}}
        >
          <img src={image} alt="" className="emoji-image" />
        </button>
      ))}
      {type == 1?<div style={{fontWeight:isNA?"bold":"",cursor:"pointer"}}onClick={() => {
        setIsNA(true)
        onChange(0)
        }} >N/A</div>:null}
    </div>
  );
};


const EmojiRates = ({ value, onChange }) => {
  return (
    <div className="rating-wrapper">
      <div className="rating-labels">
        <p>Not at all Likely</p>
        <p>Extremely Likely</p>
      </div>
      <div className="rating-container">
        {Array.from({ length: 11 }, (_, index) => (
          <button
            key={index}
            className={`rating-button ${value === index ? 'selected' : ''}`}
            onClick={() => onChange(index)}
          >
            {index}
          </button>
        ))}
      </div>
    </div>
  );
};


useEffect(() => {
    generateTempToken();
}, [])




  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: '20px' }}>  
    <div>  
       <h2>Provider Survey (Teachers Health Trust)</h2>

     {formSubmitted ? (
        <Result
          status="success"
          title="Thank you for your rating!"
          subTitle="Your feedback has been submitted successfully."
        />
      ): formAlreadySubmitted ? (
        <Result
        status="info"
        title="You've already submitted your rating!"
        subTitle="We appreciate your feedback. You've already submitted your rating earlier."
        />
      ): (
      <Form layout="vertical" onFinish={handleSubmit} style={{ width: '100%', maxWidth: '500px' }}> {/* Responsive form width */}
        <Form.Item label="Did you receive your appointment in a timely manner?">
  <Radio.Group value={appointment} onChange={e => setAppointment(e.target.value)}>
    <Radio value={1}>Yes</Radio>
    <Radio value={0}>No</Radio>
  </Radio.Group>
</Form.Item>
        <Form.Item label="How would you rate the care you received from your provider?" >
          {/* <Rate value={care} onChange={value => setCare(value)} /> */}
          <EmojiRate value={care} onChange={value => setCare(value)} type={0} />

        </Form.Item>

        <Form.Item label="How would you rate the front desk at the provider’s office?" >
          {/* <Rate value={frontDesk} onChange={value => setFrontDesk(value)} /> */}
          <EmojiRate value={frontDesk} onChange={value => setFrontDesk(value)} type={1} />

        </Form.Item>

        <Form.Item label="How likely are you to recommend this provider to other THT members?" >
          {/* <Rate value={recommendation} onChange={value => setRecommendation(value)} /> */}
          <EmojiRates value={recommendation} onChange={value => setRecommendation(value)} />

        </Form.Item>
        {recommendation <= 2?<Form.Item required={true} label="Please provide additional comments about your recommendation" 
          >
          <Input.TextArea value={likelyComment} onChange={e => setLikelyComment(e.target.value)} rows={4} placeholder="" />
        </Form.Item>:null}
        <Form.Item label="Additional Feedback (Optional)">
          <Input.TextArea value={feedback} onChange={e => setFeedback(e.target.value)} rows={4} placeholder="Share your thoughts and experiences..." />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>Submit Rating</Button>
        </Form.Item>
      </Form>
     )}
     </div> 
    </div>
  );
};

export default RatingForm;
