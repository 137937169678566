import React, { useEffect, useState } from 'react';
import { Form, Select, Rate, Button, Row, Col, Input, Modal, Result, Radio } from 'antd';
import { useDispatch } from 'react-redux';
import { setTempToken } from '../../../features/auth/authSlice';
import { getClientSecret, getClient_id } from '../../../utils/functions';
import axiosHIT from 'axios';
import { apiHitWithTempToken } from '../../../service/authService';
import { LikeOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './rating.css';
import { veryunhappy, unhappy, happy, veryhappy } from '../../../utils/images';

const { Option } = Select;

const PatientRating = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  const [likelyComment, setLikelyComment] = useState(''); // Optional field for additional comments
  const [isNA, setIsNA] = useState(false);

    const clinic_ID = queryParams.get('clinic_ID');

    const [appointment, setAppointment] = useState('');
    const [care, setCare] = useState(0);
    const [frontDesk, setFrontDesk] = useState(0);
    const [recommendation, setRecommendation] = useState(10);
    const [patientName, setPatientName] = useState('');
    const [clinicName, setClinicName] = useState('');
    const [clinicAddress, setClinicAddress] = useState('');
    const [isLoadingClinicInfo, setIsLoadingClinicInfo] = useState(true);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false);

    // New function to fetch clinic information
    const fetchClinicInfo = async () => {
        setIsLoadingClinicInfo(true);
        try {
            const response = await axiosHIT.get(
                `https://api.medsetgo.com/apis/v1.2/testsendgrid.php?action=get_clinic_info&&clinicid=${clinic_ID}`
            );
            
            console.log('api response', response);
            if (response.data && response.data.status === 200) {
                const clinicData = response.data.result;
                setClinicName(clinicData.clinicname || '');
                setClinicAddress(clinicData.clinicaddress || '');
            }
        } catch (error) {
            console.error('Error fetching clinic information:', error);
        } finally {
            setIsLoadingClinicInfo(false);
        }
    };

    useEffect(() => {
        if (clinic_ID) {
            fetchClinicInfo();
        }
    }, [clinic_ID]);

    const handleInputChange = (e, field) => {
        switch (field) {
            case 'patientName':
                setPatientName(e.target.value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (event) => {
        console.log('Patient Name:', patientName);
        console.log('Clinic Name:', clinicName);
        console.log('Clinic Address:', clinicAddress);
        console.log('Appointment:', appointment);
        console.log('Care Rating:', care);
        console.log('Front Desk Rating:', frontDesk);
        console.log('Recommendation Rating:', recommendation);
        hitapi();
    };

    const hitapi = async() => {
        setIsLoading(true);
        try {
            const FD = new FormData();
            FD.append("action", "submit_provider_ratting_by_qr");
            FD.append("rate_app_manner", appointment);
            FD.append("rate_care_provider", care);
            FD.append("rate_front_desk", isNA == true ? 4: frontDesk);
            FD.append("rate_recomendation", recommendation);
            FD.append("clinic_ID", clinic_ID);
            FD.append("comment", feedback);
            FD.append("patientname", patientName);
            FD.append("rate_recomendation_comment", recommendation <= 2 ?likelyComment:"");

            let res = await apiHitWithTempToken(FD);
            setIsLoading(false);
            if (res.data.status === 200) {
                setFormSubmitted(true);
            } else if (res.data.status === 400) {
                setFormAlreadySubmitted(true);
            }
        } catch (error) {
            console.error('API Error:', error);
        }
        setIsLoading(false);
    };

    const authStep1 = (code) => {
        const formdata = new FormData();
        formdata.append("client_id", getClient_id());
        formdata.append("client_secret", getClientSecret());
        formdata.append("grant_type", code ? 'authorization_code' : "client_credentials");
        if(code) {
            formdata.append("code", code);
        }
    
        return axiosHIT({
            url: `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/Token.php`,
            method: "post",
            data: formdata,
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            },
        });
    };

    const generateTempToken = async () => {
        try {
            const res = await authStep1();
            console.log('token temp response', res);
            if (res.status === 200) {
                dispatch(setTempToken(res?.data?.access_token));
            }
        } catch (error) {}
    };

    const images = [veryunhappy, unhappy, happy, veryhappy];

    const EmojiRate = ({ value, onChange,type }) => {
        return (
          <div className="image-container">
            {images.map((image, index) => (
              <button
                key={index}
                className={`image-button ${value > index ? 'selected' : ''}`}
                onClick={() => {
                  if(type == 1){
                    setIsNA(false)
                  }
                  onChange(index + 1)}}
              >
                <img src={image} alt="" className="emoji-image" />
              </button>
            ))}
            {type == 1?<div style={{fontWeight:isNA?"bold":"",cursor:"pointer"}}onClick={() => {
              setIsNA(true)
              onChange(0)
              }} >N/A</div>:null}
          </div>
        );
      };
      
    const EmojiRates = ({ value, onChange }) => {
        return (
            <div className="rating-wrapper">
                <div className="rating-labels">
                    <p>Not at all Likely</p>
                    <p>Extremely Likely</p>
                </div>
                <div className="rating-container">
                    {Array.from({ length: 11 }, (_, index) => (
                        <button
                            key={index}
                            className={`rating-button ${value === index ? 'selected' : ''}`}
                            onClick={() => onChange(index)}
                        >
                            {index}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    useEffect(() => {
      generateTempToken();
  }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: '20px' }}>
            <div>
                <h2>Patient Survey (Teachers Health Trust)</h2>

                {formSubmitted ? (
                    <Result
                        status="success"
                        title="Thank you for your rating!"
                        subTitle="Your feedback has been submitted successfully."
                    />
                ) : formAlreadySubmitted ? (
                    <Result
                        status="info"
                        title="You've already submitted your rating!"
                        subTitle="We appreciate your feedback. You've already submitted your rating earlier."
                    />
                ) : (
                    <Form layout="vertical" onFinish={handleSubmit} style={{ width: '100%', maxWidth: '500px' }}>
                        <Form.Item 
                            label="Patient Name" 
                            name="patientName"
                            rules={[{ required: true, message: 'Please enter patient name' }]}
                        >
                            <Input 
                                placeholder="Enter patient name"
                                value={patientName}
                                onChange={(e) => handleInputChange(e, 'patientName')}
                            />
                        </Form.Item>

                        <Form.Item label="Clinic Name">
                            <Input 
                                value={clinicName}
                                disabled
                                placeholder={isLoadingClinicInfo ? "Loading clinic information..." : ""}
                            />
                        </Form.Item>

                        <Form.Item label="Clinic Address">
                            <Input 
                                value={clinicAddress}
                                disabled
                                placeholder={isLoadingClinicInfo ? "Loading clinic information..." : ""}
                            />
                        </Form.Item>

                        <Form.Item label="Did you receive your appointment in a timely manner?">
                            <Radio.Group value={appointment} onChange={e => setAppointment(e.target.value)}>
                                <Radio value={1}>Yes</Radio>
                                <Radio value={0}>No</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label="How would you rate the care you received from your provider?">
                            <EmojiRate value={care} onChange={value => setCare(value)} type={0}/>
                        </Form.Item>

                        <Form.Item label="How would you rate the front desk at the provider's office?">
                            <EmojiRate value={frontDesk} onChange={value => setFrontDesk(value)} type={1} />
                        </Form.Item>

                        <Form.Item label="How likely are you to recommend this provider to other THT members?">
                            <EmojiRates value={recommendation} onChange={value => setRecommendation(value)} />
                        </Form.Item>
    {recommendation <= 2?<Form.Item required={true} label="Please provide additional comments about your recommendation" 
          >
          <Input.TextArea value={likelyComment} onChange={e => setLikelyComment(e.target.value)} rows={4} placeholder="" />
        </Form.Item>:null}
                        <Form.Item label="Additional Feedback (Optional)">
                            <Input.TextArea 
                                value={feedback} 
                                onChange={e => setFeedback(e.target.value)} 
                                rows={4} 
                                placeholder="Share your thoughts and experiences..." 
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
                                Submit Rating
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default PatientRating;