import { createAsyncThunk } from "@reduxjs/toolkit";
import { message, Modal } from "antd";
import moment from "moment/moment";
import { generateOne } from "../../utils/functions";
import { getUsers, postApi } from "./service";
import { initialServiceDetailval } from "./Slice";
import {
  OrderSuccess,
  setExerciseProtocals,
  setExerciseTherapy,
  setSubCategoryByCity,
  setTopCategories,
  setUserInsurance,
  toggleAddNewAdress,
  UpdateplacingOrder,
  setServiceData,
} from "./Slice";

export const getUserAddress = createAsyncThunk(
  "getUserAddress",
  async (payload, { getState }) => {
    try {
      const { user, service } = getState();
      const formData = new FormData();
      formData.append("action", "get_society_user_address");
      formData.append("society_id", user?.user?.society_id);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);
      formData.append("userId", service?.serviceDetail?.user_id);
      console.log("user", user);
      console.log("service", service);
      const res = await postApi(formData, user?.token);
      if (res?.data?.status === 200) {
        return res?.data?.result ?? [];
      }
      return [];
    } catch (error) {
      return [];
    }
  }
);

export const deleteShiping = createAsyncThunk(
  "DeleteShipping",
  async (shippingId, { getState }) => {
    try {
      message.destroy();
      message.loading("Deleteing... please wait.");
      const { user } = getState();
      const formData = new FormData();
      formData.append("action", "delete_user_shipping");
      formData.append("shippingId", shippingId);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);

      const res = await postApi(formData, user?.token);
      if (res?.data?.status === 200) {
        message.destroy();
        message.success("Selected address deleted successfully");
        return shippingId;
      }
      message.destroy();

      message.error("Failed to deleted address");
      return null;
    } catch (err) {
      message.destroy();

      message.error("Failed to delete the address");
      return null;
    }
  }
);

export const getUserList = createAsyncThunk(
  "getPatientList",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    try {
      const { user } = getState();

      const formData = new FormData();
      formData.append("action", "get_patient_list");
      formData.append("societyid", user?.user?.society_id);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);
      formData.append("societyId", user?.user?.society_id);
      if (payload?.f_name) {
        formData.append("f_name", payload?.f_name);
      }
      if (payload?.l_name) {
        formData.append("l_name", payload?.l_name);
      }
      const response = await getUsers(formData, user?.token);
      if (response?.data?.status === 200) {
        return response?.data?.result ?? [];
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }
);

export const saveNewAddress = createAsyncThunk(
  "saveNewAddress",
  async (payload, { getState, dispatch }) => {
    try {
      if (payload?.shippingId) {
        message.destroy();
        message.loading("Updating...  Address");
      } else {
        message.destroy();
        message.loading("Addding... Address");
      }
      const { user } = getState();
      const formData = new FormData();
      // shippingId
      formData.append("society_id", user?.user?.society_id);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);

      Object.keys(payload ?? {}).map((k) => formData.append(k, payload?.[k]));

      const res = await postApi(formData, user?.token);

      if (res?.data?.status === 200) {
        dispatch(getUserAddress());
        if (payload?.shippingId) {
          message.destroy();
          message.success("Address updated successfully");
          dispatch(toggleAddNewAdress(false));
        } else {
          message.destroy();
          message.success("Address added successfully");
          dispatch(toggleAddNewAdress(false));
        }
        return;
      } else {
        if (payload?.shippingId) {
          message.destroy();
          message.error("Failed to update address");
          dispatch(toggleAddNewAdress(false));
        } else {
          message.destroy();
          message.success("Failed to add address");
          dispatch(toggleAddNewAdress(false));
        }
        return null;
      }
    } catch (error) {
      if (payload?.shippingId) {
        message.destroy();
        message.error("Failed to update address");
        dispatch(toggleAddNewAdress(false));
      } else {
        message.destroy();
        message.success("Failed to add address");
        dispatch(toggleAddNewAdress(false));
      }
      return null;
    }
  }
);

export const getServiceCategories = createAsyncThunk(
  "getServiceTypes",
  async (p, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const { serviceDetail } = service;
      const { user: u, token } = user;
      const FD = new FormData();
      FD.append("lat", serviceDetail?.lat || serviceDetail?.latitude);
      FD.append("lng", serviceDetail?.lng || serviceDetail?.latitude);
      FD.append("zipcode", serviceDetail?.pincode || "");
      FD.append("deliverytype",  serviceDetail?.facilityId === "1"?"1": serviceDetail?.deliverytype || 0);
      FD.append("societyId", u?.society_id);
      FD.append("action", "city_category_2");
      FD.append("areacode", u?.areacode);

      FD.append("auterizetokenid", u?.id);
      FD.append("cityId", serviceDetail?.city || "");
      FD.append("userId", u?.id);
      FD.append("isfacility", serviceDetail?.facilityId);
      const res = await postApi(FD, token);
      if (res?.data?.status === 200) {
        dispatch(setTopCategories(res?.data ?? {}));
      } else {
        dispatch(setTopCategories({}));
      }
    } catch (error) {
      dispatch(setTopCategories({}));
    }
  }
);

export const getExerciseTherapy = createAsyncThunk(
  "getExcerciseTherapy",
  async (p, { getState, dispatch }) => {
    const { user } = getState();
    try {
      const { user: u, token } = user;
      const FD = new FormData();

      FD.append("societyId", u?.society_id);
      FD.append("action", "get_exercise_therapy");
      FD.append("auterizetokenid", u?.id);
      FD.append("userId", u?.id);
      const res = await postApi(FD, token);
      if (res?.data?.status) {
        dispatch(setExerciseTherapy(res?.data?.result ?? []));
      }
    } catch (error) {
      dispatch(setExerciseTherapy([]));
    }
  }
);

export const getExerciseProtocals = createAsyncThunk(
  "getExerciseProtocals",
  async (p, { getState, dispatch }) => {
    const { user } = getState();
    try {
      const { user: u, token } = user;
      const FD = new FormData();
      FD.append("societyId", u?.society_id);
      FD.append("action", "get_therapy_protocols");
      FD.append("auterizetokenid", u?.id);
      FD.append("userId", u?.id);
      const res = await postApi(FD, token);

      if (res?.data?.status) {
        dispatch(setExerciseProtocals(res?.data?.result ?? []));
      }
    } catch (error) {
      dispatch(setExerciseProtocals([]));
    }
  }
);

export const getOrderPrice = createAsyncThunk(
  "getOrderPriceForVendor",
  async (payload, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const { serviceDetail } = service;

      const serviceId = () => {
        const Ids = [];
        if (serviceDetail?.category_id?.length > 0) {
          (serviceDetail?.category_id ?? []).map((cat_id) =>
            (serviceDetail?.[cat_id] ?? []).map((sub_id) => Ids.push(sub_id))
          );
        }
        return Ids ?? [];
      };

      const formData = new FormData();
      formData.append("partnerId", payload?.partnerId);
      formData.append("serviceId", [...serviceId()].join(","));
      formData.append("action", "get_service_price_for_vendor");
      formData.append("auterizetokenid", user?.user?.id);
      const res = await postApi(formData, user?.token);
      if (res?.status === 200) return res?.data?.service ?? [];
    } catch (error) {
      return [];
    }
  }
);

export const checkAvailability = createAsyncThunk(
  "check_availability",
  async (payload, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const FD = new FormData();
      const { user: u, token, serviceDetail } = { ...user, ...service };
      FD.append("zip_code", serviceDetail?.pincode || "");
      FD.append("deliverytype", 0);
      FD.append(
        "dateone",
        moment(serviceDetail?.schedule_date).format("YYYY-MM-DD")
      );
      if (payload?.insuranceId) {
        FD.append("insuranceid", payload?.insuranceId);
      }
      FD.append("action", "check_availablilty");
      FD.append("auterizetokenid", u?.id);
      const res = await postApi(FD, token);
      if (res?.status === 200) {
        if (res?.data?.partnerId) {
          dispatch(getOrderPrice({ partnerId: res?.data?.partnerId }));
        }

        return res?.data ?? {};
      }
    } catch (error) {
      return { loading: false };
    }
  }
);

export const getUserInsurance = createAsyncThunk(
  "getUserInsurance",
  async (payload, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const FD = new FormData();
      const { user: u, token, serviceDetail } = { ...user, ...service };
      FD.append("userId", serviceDetail?.user_id);
      FD.append("familymemberId", "");
      FD.append("action", "get_user_insurance_all");
      FD.append("auterizetokenid", u?.id);
      FD.append("clinicId", u?.society_id);

      const res = await postApi(FD, token);
      if (res?.data?.status === 200) {
        dispatch(setUserInsurance(res?.data?.result ?? []));
      }
    } catch (error) {
      dispatch(setUserInsurance([]));
    }
  }
);
export const getSubcategoryByCity = createAsyncThunk(
  "getSubcategoryByCity",
  (payload, { getState, dispatch }) => {
    try {
    } catch (error) {}
  }
);

export const getCategoryByCity = createAsyncThunk(
  "getCategoryByCity",
  async (payload, { getState, dispatch }) => {
    const { user } = getState();
    try {
      const { user: u, token } = user;
      const FD = new FormData();
      FD.append("category_id", payload?.category_id);
      FD.append("city_id", payload?.city_id);
      FD.append("country", payload?.country);
      FD.append("lat", payload?.lat);
      FD.append("lng", payload?.lng);
      FD.append("action", "all_subcategory_by_city");
      FD.append("auterizetokenid", u?.id);
      FD.append("userId", u?.id);
      const res = await postApi(FD, token);
      if (res?.status === 200) {
        dispatch(
          setSubCategoryByCity({
            [payload?.category_id]: {
              discountAmount: res?.data?.discountAmount || 0,
              data: res?.data?.result ?? [],
            },
          })
        );
      }
    } catch (error) {
      dispatch(setSubCategoryByCity({}));
    }
  }
);

export const placeOrder = createAsyncThunk(
  "placeOrder",
  async (payload, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const { category_id ,vendorid} = payload;
      const {
        user: u,
        token,
        serviceDetail,
        topCategories,
      } = { ...user, ...service };
      const FD = new FormData();
      FD.append("action", "new_payment");
      FD.append("cpt", serviceDetail?.cpt || "");
      FD.append("dx", serviceDetail?.dx || "");
      FD.append("first_name", serviceDetail?.first_name || "");
      FD.append("last_name", serviceDetail?.last_name || "");
      FD.append("email", serviceDetail?.email || "");
      FD.append("phone", serviceDetail?.phone || "");
      FD.append("latitude", serviceDetail?.latitude || "");
      FD.append("longitude", serviceDetail?.longitude || "");
      FD.append(
        "schedule_date",
        serviceDetail?.schedule_date !== ""
          ? moment(serviceDetail?.schedule_date, "MM-DD-YYYY").format(
              "YYYY-MM-DD"
            )
          : ""
      );
      FD.append("appointmentid", serviceDetail?.appointmentid || "0");
      FD.append("patientId", serviceDetail?.patientId || "0");
      FD.append("payment_amount", serviceDetail?.payment_amount || "");
      FD.append(
        "execution_date",
        serviceDetail?.execution_date !== ""
          ? moment(serviceDetail?.execution_date, "MM-DD-YYYY").format(
              "YYYY-MM-DD"
            )
          : ""
      );
      FD.append("visitid", serviceDetail?.visitid || "");
      FD.append("timeSlot", "");
      FD.append("timeslotid", "");
      FD.append("device_type", "web");
      FD.append("timeslotid", "");
      FD.append("category_id", category_id);
      FD.append("user_id", serviceDetail?.user_id);
      FD.append("casemanager", u?.id);
      FD.append("partnerselection", serviceDetail?.partnerselection || "0");

      FD.append("OTHDec", "");
      FD.append("country", serviceDetail?.country || "");

      FD.append("usershipping_id", serviceDetail?.shippingId || "");
      FD.append("is_fleet", "");
      FD.append("isPharmacy", "");
      FD.append("vendorid", vendorid);
      FD.append("no_occurence", serviceDetail?.no_occurence);
      FD.append("iscarservice", 0);
      FD.append("walletMoney", 0);
      FD.append("referalid", 0);
      FD.append("occonweekly", serviceDetail?.occonweekly);
      FD.append("Isinsurance", serviceDetail?.Isinsurance || 0);
      if (serviceDetail?.Isinsurance > 0) {
        FD.append("Isinsurance", serviceDetail?.Isinsurance || "");
      }
      FD.append("insuranceId", serviceDetail?.insuranceId || "0");
      FD.append("doctorId", serviceDetail?.doctorId || "");

      FD.append("services", category_id && serviceDetail?.[category_id].join());
      FD.append(
        "quantity",
        serviceDetail?.[category_id]?.length > 0
          ? generateOne(serviceDetail?.[category_id]?.length ?? 1)
          : 1
      );
      // -----------------only for "Physical Therapy"
      if (
        topCategories?.topcategories?.filter(
          (el) =>
            el?.category_id === category_id &&
            el?.category_name === "Physical Therapy"
        )?.length > 0
      ) {
        FD.append(
          "exercise_therapy",
          JSON.stringify({
            exercise_therapy: serviceDetail?.exercise_therapy || [],
          })
        );

        FD.append(
          "therapy_protocols",
          JSON.stringify({
            therapy_protocols: serviceDetail?.therapy_protocols || [],
          })
        );
      }
      // ----------------only for   "Home Hospice Care"
      if (
        topCategories?.topcategories?.filter(
          (el) =>
            el?.category_id === category_id &&
            el?.category_name === "Home Hospice Care"
        )?.length > 0
      ) {
        FD.append(
          "patient_location",
          JSON.stringify({
            patient_location: serviceDetail?.patient_location || [],
          })
        );
        FD.append("dme", JSON.stringify({ dme: serviceDetail?.dme || [] }));
        FD.append("DNR", serviceDetail?.DNR || 2);
        FD.append("AD", serviceDetail?.AD || 2);
        FD.append(
          "terminal_diagnosis",
          serviceDetail?.terminal_diagnosis || ""
        );
        FD.append("co_morbidities", serviceDetail?.co_morbidities || "");
      }

      // ----------only for -- "Comprehensive Nursing Care"

      if (
        topCategories?.topcategories?.filter(
          (el) =>
            el?.category_id === category_id &&
            el?.category_name === "Comprehensive Nursing Care"
        )?.length > 0
      ) {
        FD.append(
          "exercise_therapy",
          JSON.stringify({
            exercise_therapy: serviceDetail?.exercise_therapy || [],
          })
        );

        FD.append(
          "therapy_protocols",
          JSON.stringify({
            therapy_protocols: serviceDetail?.therapy_protocols || [],
          })
        );
      }

      // ------
      FD.append("home_health", JSON.stringify({}));
      FD.append("primary_diagnosis", "");
      FD.append("SN", JSON.stringify({}));

      FD.append("orders", "");
      FD.append("societyId", u?.society_id || "");
      FD.append("ampm", serviceDetail?.ampm || "");
      FD.append("auterizetokenid", u?.id);

      FD.append("deliverytype", serviceDetail?.deliveryType);
      FD.append("facilityId", serviceDetail?.facilityId);
      FD.append(
        "appointmentInstructions",
        serviceDetail?.appointmentInstructions || ""
      );
      FD.append("prescription_text", serviceDetail?.prescription_text);
      FD.append("mri", JSON.stringify(serviceDetail?.mriScanData));
      FD.append("mr_extermity", JSON.stringify(serviceDetail?.mrExtremity));
      FD.append("mr_angioraphy", JSON.stringify(serviceDetail?.mrAngiography));
      FD.append(
        "mr_arthrogrphy",
        JSON.stringify(serviceDetail?.mrArthrography)
      );
      FD.append("ct", JSON.stringify(serviceDetail?.ctScan));
      FD.append("ct_angioraphy", JSON.stringify(serviceDetail?.ctAngiography));
      FD.append("ultrasound", JSON.stringify(serviceDetail?.ultraSound));
      FD.append("x_ray", JSON.stringify(serviceDetail?.xRay));
      FD.append(
        "nuclear_medicine",
        JSON.stringify(serviceDetail?.nuclearMedicine)
      );
      FD.append("fluoroscopy", JSON.stringify(serviceDetail?.fluoroScopy));
      FD.append("dexa", JSON.stringify(serviceDetail?.dexa));
      FD.append("priorityId", serviceDetail?.orderClassificationValue);
      FD.append("ultrasoundatt", serviceDetail?.ultraSoundRadioValue || "");

      if (
        topCategories?.topcategories?.filter(
          (el) =>
            el?.category_id === category_id &&
            el?.category_name === "Durable Medical Equipment"
        )?.length > 0
      ) {
        FD.append("dme_code", serviceDetail?.dmeCodesList || "");
      } else {
        FD.append("dme_code", "");
      }

      if ((serviceDetail?.prescriptions ?? []).length > 0) {
        (serviceDetail?.prescriptions ?? []).forEach((file) => {
          FD.append("prescriptions[]", file?.originFileObj, file.name);
        });
      }

      FD.append("attachmentIds", serviceDetail?.attachmentIds || "");
      //  console.log("vendorid", serviceDetail?.vendorid);

      const res = await postApi(FD, token);
      console.log(res?.data.status)
      if (res?.status === 200 && res?.data.status === 200) {
        dispatch(setServiceData({ ...initialServiceDetailval }));
        
        dispatch(UpdateplacingOrder(false));
        dispatch(OrderSuccess(true));
      } else {
        Modal.warning({
          title: "Something went wrong, please try again",
        });
        dispatch(UpdateplacingOrder(false));
      }
    } catch (error) {
      dispatch(setServiceData({ ...initialServiceDetailval }));
      dispatch(UpdateplacingOrder(false));
    }
  }
);
