import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "../../utils/functions";
import { CaseMangerWrapper } from "../../components/Wrapper";
import {
  getAppointments,
  updateAppointment,
} from "../../features/case-manager/caseManagerSlice";
import { Button, Input, Table, Tooltip, Dropdown, Menu, Modal, message, Spin } from "antd";
import "./style.css";
import { FiSearch } from "react-icons/fi";
import {
  AiFillFileUnknown,
  AiFillPlusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { NoData } from "../../components/common/Nodata/index";
import { FaDownload, FaFilter } from "react-icons/fa";
import FileSaver from "file-saver";
import {
  initialServiceDetailval,
  setServiceData,
  setServiceLocationData,
  updateServiceStep,
} from "../../store/AddService/Slice";
import { serviceSteps } from "../../utils/constants";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import moment from "moment";
import PaginationUI from "../../components/common/pagination";
import { BsChatLeftTextFill, BsImages } from "react-icons/bs";

import UpdateOrder from "../../components/common/modals/UpdateOrder";
// import { RowAction } from "../../components/common/dropdowns/RowAction";
import AttachmentsView from "../../components/common/drawer/AttachmentsView";
import { setCurrentChat } from "../../store/chat/Slice";
import * as XLSX from "xlsx";
import { AiOutlinePlus } from "react-icons/ai";

import { FaDownLong, FaGears } from "react-icons/fa6";
import { PdfViewer } from "../../components/common/Viewer";
import { BiCross, BiSolidFilePdf, BiSolidNote } from "react-icons/bi";
import { APIHitCall, APIHitCallWithBlob } from "../../features/case-manager/caseManagerService";
import FormItem from "antd/es/form/FormItem";
import { makeAndsaveExcelFile } from "../../utils/functions";
import FilterOptionsDropdown from "./filter";
import { isMobile } from "react-device-detect";
import { ImCancelCircle } from "react-icons/im";
import { apihitCall } from "../../service/authService";
import axios from "axios";
export const statusStyles = {
  "0": { text: "New", color: "#000000" }, // Green
  "1": { text: "Assign", color: "#2196f3" }, // Blue
  "2": { text: "Not Interested", color: "#ff9800" }, // Orange
  "3": { text: "Assigned", color: "#4caf50" }, // Purple
  "4": { text: "Accepted", color: "#4caf50" }, // Green
  "5": { text: "Rejected", color: "#f44336" }, // Red
  "6": { text: "Cancelled", color: "#f44336" } ,
  "7": { text: "Completed", color: "#4caf50" }, // Green
  // Red

};

export const statusStylesSecond = {
  "0": { text: "New", color: "#000000" }, // Green
  "1": { text: "Interested", color: "#2196f3" }, // Blue
  "2": { text: "Not Interested", color: "#ff9800" }, // Orange
  "3": { text: "Assigned", color: "#4caf50" }, // Purple
  "4": { text: "Accepted", color: "#4caf50" }, // Green
  "5": { text: "Rejected", color: "#f44336" }, // Red
  "6": { text: "Cancelled", color: "#f44336" } ,
  "7": { text: "Completed", color: "#4caf50" }, // Green
  // Red

};
export const AppointmentPage = () => {
  //console.log("gagan");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  // const [currentRow, setCurrentRow] = useState(null);
  // const [showDetail, setShowDetail] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isPdf, setPdf] = useState(null);
  const [isPdfLoading, setPdfLoading] = useState("");

  const [doctorId, setdoctorId] = useState("");
  const [referralStatusId, setReferralStatusId] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [servicePartnerId, setservicePartnerId] = useState("");
  const [serviceTypeId, setserviceTypeId] = useState("");

  const [isExporting, setIsExporting] = useState(false);

  const [isUpdate, setUpdatedata] = useState(null);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [isAttchments, setIsAttachments] = useState(false);

  const [cancelData, setCancelData] = useState(null);


  const [search, setSearch] = useState("");
  const { appointment, user, fcmToken } = useSelector(
    ({ caseManagerSlice, user }) => ({
      ...caseManagerSlice,
      ...user,
    })
  );

  const getListData = (FD) => {
    dispatch(getAppointments(FD));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceApicall = useCallback(debounce(getListData), []);

  const exportData = () => {
    if (!appointment || !appointment.data || appointment.data.length === 0) {
      return;
    }
    let header = [
      "OrderId",
      "Patient Name",
      "Service",
      "Schedule Date",
      "Address",
      "Phone",
      "Service Partner",
      "Payment",
      "Clinic",
      "Doctor",
    ];
    getExportData();
    async function getExportData() {
      setIsExporting(true);
      try {
        const FD = new FormData();
        FD.append("casemanager", user?.id);
        FD.append("action", "inprocessTaskcasemanager");
        FD.append("auterizetokenid", user?.id);
        FD.append("limit", 500);
        // FD.append("page_start", (page - 1) * limit);
        FD.append("deliverytype", "0");

        FD.append("doctorId", doctorId);
        FD.append("start_date", startdate);
        FD.append("end_date", enddate);
        FD.append("partnerid", servicePartnerId);
        FD.append("type", serviceTypeId);
        FD.append("jobstatus", referralStatusId || "99");

        if (search) {
          FD.append("name", search);
        }
        const res = await APIHitCall(FD);
        setIsExporting(false);
        if (res?.data?.status === 200) {
          const data = res?.data?.result?.map((item) => ({
            OrderId: item.order_id,
            "Patient Name": item.first_name + " " + item.last_name,
            Service: item.services,
            "Schedule Date": item.schedule_datetime,
            Address: item.address,
            Phone: item.mobile,
            "Service Partner": item.parentpartnername,
            Payment: item.payment_status,
            Clinic: item.clinicname,
            Doctor: item.docname + item.doclname,
          }));
          makeAndsaveExcelFile(header, data);
        }
      } catch (error) {
        console.error(error);
        setIsExporting(false);
      } finally {
        setIsExporting(false);
      }
    }
  };
   
  const fetchData = () => {
    const FD = new FormData();
    FD.append("casemanager", user?.id);
    FD.append("action", "inprocessTaskcasemanager");
    FD.append("auterizetokenid", user?.id);
    FD.append("limit", limit);
    FD.append("page_start", (page - 1) * limit);
    FD.append("deliverytype", "0");

    FD.append("doctorId", doctorId);
    FD.append("start_date", startdate);
    FD.append("end_date", enddate);
    FD.append("partnerid", servicePartnerId);
    FD.append("type", serviceTypeId);
    FD.append("jobstatus", referralStatusId || "99");

    if (search) {
      FD.append("name", search);
    }
    debounceApicall(FD);
  };

  const updateFcmToken = async () => {
    try {
      const FD = new FormData();
     
      FD.append("action", "updatedFCM");
      FD.append("webfcm", fcmToken);
      FD.append("auterizetokenid", user?.id);

      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        console.log('api message', res?.data?.msg);
      } else {
        console.log('token not updated',fcmToken);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const assignReferral = async (row) => {
    try {
      const FD = new FormData();
     
      FD.append("action", "assign_referral_to_provider");
      FD.append("orderId", row?.order_id);
      FD.append("auterizetokenid", user?.id);
      
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
fetchData();
      } else {
        
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(updateAppointment([]));
  }, []);

  useEffect(() => {
    updateFcmToken();
  }, [fcmToken]);

  useMemo(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    limit,
    page,
    // search,
    // doctorId,
    // startdate,
    // enddate,
    // servicePartnerId,
    // serviceTypeId,
    // referralStatusId,
  ]);
  
  useMemo(() => {
    setLimit(10);
    setPage(1);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
   
    search,
    doctorId,
    startdate,
    enddate,
    servicePartnerId,
    serviceTypeId,
    referralStatusId,
  ]);

  const onUpdate = (data) => {
    setUpdatedata(data);
  };

  const onClickAddOrder = (d) => {
    const p = {
      appointmentid: "",
      user_id: d?.user_id,
      phone: d?.mobile,
      email: d?.patientemail,
      dob: d?.patientdob,
      gender: d?.gender,
      insuranceId: d?.insuranceId || "",
      orderId: d?.order_id,
    };

    dispatch(setServiceData({ ...initialServiceDetailval, ...p }));
    dispatch(updateServiceStep(serviceSteps.SELECT_ADDRESS));
    navigation("/case-manager/request-service");
  };
  const onCloseUpateOrder = () => {
    setUpdatedata(null);
  };
 
  const onAttachmentsView = (data) => {
    setAttachmentsData(data?.pharmacyinfo?.prescription_image ?? []);
    setIsAttachments(true);
  };
  const onCloseAttachments = () => {
    setIsAttachments(false);
    setAttachmentsData([]);
  };

   
  const onClickDownloadReferral = async (row) => {
    setPdfLoading(row?.order_id);
    const FD = new FormData();
  
    FD.append("action", "merge_homehealth_referral");
    FD.append("referralid", row?.order_id);
    FD.append("auterizetokenid", user?.id);
  
    try {
      // Make sure your API call expects binary data
      const res = await APIHitCallWithBlob(FD); // Ensure API call is set to 'blob'
      
      if ( res && res?.status === 200 && res.data instanceof Blob && res.data.size > 40 && res.data.status !== 400) {
        // If the response is a valid Blob and not empty
        const blobURL = URL.createObjectURL(res.data);
       
        setPdfLoading("");
      //  window.open(blobURL, "_blank");

        const a = document.createElement("a");
  a.href = blobURL;
  a.target = "_blank"; // Open in a new tab
  a.download = `referral_${row?.order_id}_${row?.first_name}.pdf`; // Optional: Prompt to download the file with this name
  a.click();

  // Clean up the blob URL after some time
  setTimeout(() => URL.revokeObjectURL(blobURL), 1000);
      } else {
         message.error("Pdf not available")
      }
    } catch (error) {
      message.error("Pdf not available")
      console.error("Error fetching the PDF:", error.message || error);
      // Optionally show a toast or error message to the user
    } finally {
      // Clear loading state once the operation completes
      setPdfLoading("");
    }
  };
  

  const columns = [
    {
      title: "#",
      dataIndex: "order_id",
      render: (item) => item,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      render: (item, row) =>
        `${row?.first_name ?? ""} ${row?.last_name ?? ""}` ?? row?.username,
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      render: (item) => item,
    },
    {
      title: "Type",
      dataIndex: "services",
      width: 200,
      render: (item) => item ?? "-",
    },
    {
      title: "Partner",
      dataIndex: "parentpartnername",
      render: (item, row) => {
        return <div style={{display:"flex",flexDirection:"column"}}>
           <div>{item}</div>
           {row?.rejectedby !== ""?<div style={{color:"red"}}>Rejected by : {row?.rejectedby}</div>:null}
        </div>
      }
     
      
    },
    {
      title: "Date",
      width: "12%",
      dataIndex: "schedule_datetime",
      render: (item) => (item ? moment(item).format("MM-DD-YYYY") : "N/A"),
    },
    {
      title: "Status",
      dataIndex: "accept_status",
      render: (item,row) => {
     
        const status = statusStyles[item];
        
      
        if (status) {
          return (
            <span
             
              onClick={item === "1" ? (e) => { 
                e.preventDefault();
                e.stopPropagation();
                assignReferral(row)
               } : undefined}
              className={`data-${item}`}
              style={{ display:"flex",flexDirection:"column",color: status.color,cursor:`${item === "1"?"pointer":""}` }}
            >
              <div>
              {status.text}

              </div>
              {item === "6"?<div style={{color:'black', maxWidth:"160px"}}>  
                            {row?.cancel_reason}

                
                </div>:null}
            </span>
          );
        }
      
        return null;
      },
    },
    {
      title: "Chat",
      dataIndex: "user_id",
      render: (item, row) => (
        <div
          className="p-3 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              setCurrentChat({
                vendorid: row?.parentpartnerid,
                vendorname: row?.parentpartnername,
              })
            );
          }}
        >
          <BsChatLeftTextFill className="text-primary" size={20} />
        </div>
      ),
    },
    {
      title: "action",
      dataIndex: "user_id",
      align: "left",
      width: 50,
      render: (item, row) => (
        <div
          style={{ height: 40 }}
          className="d-flex text-right justify-content-end align-items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Tooltip title="View Attachments">
            <BsImages
              className="hover-text-primary cursor-pointer"
              size={22}
              onClick={() => onAttachmentsView(row)}
            />
          </Tooltip>
          <Tooltip title="Update Order">
            <FaGears
              className="hover-text-primary cursor-pointer ml-1"
              size={24}
              onClick={() => onUpdate(row)}
            />
          </Tooltip>
          {row?.careplansignlink ? (
            <Tooltip title="View Pdf">
              <BiSolidFilePdf
                className="hover-text-primary cursor-pointer ml-1"
                size={22}
                // onClick={() => window.open(row?.careplansignlink, "_blank")}
                onClick={() => setPdf(row)}
              />
            </Tooltip>
          ) : (
            <AiFillFileUnknown className=" cursor-not-allowed ml-1" size={22} />
          )}

          <Tooltip title="Add Order">
            <AiFillPlusCircle
              className="hover-text-primary cursor-pointer ml-1"
              size={24}
              onClick={() => onClickAddOrder(row)}
            />
          </Tooltip>

          {  row?.accept_status === "6" ||  row?.accept_status === "7" ||  row?.accept_status === "4"  ?null:<Tooltip title="Cancel Referral">
              <ImCancelCircle
                className="hover-text-primary cursor-pointer ml-1"
                size={22}
                // onClick={() => window.open(row?.careplansignlink, "_blank")}
                onClick={() => setCancelData(row)}
              />
            </Tooltip>}


            <Tooltip title="Download Referral">
            {isPdfLoading === row?.order_id?<Spin style={{marginLeft:"5px"}}/>:<FaDownload
              className="hover-text-primary cursor-pointer ml-1"
              size={24}
              onClick={() => onClickDownloadReferral(row)}
            />}
          </Tooltip>
        </div>
      ),
    },
  ];

  const openAppointmentDetail = (row) => {
    navigation(`/case-manager/bookings/${row?.order_id}`);
  };

  return (
    <div style={styles.main}>
      <CaseMangerWrapper>
        <section className="booking-process">
          <div className="">
            <div className="data-table-main-box">
              { ( 
                <div className="datatable-list-head appointments-list">
                  <div className="datatable-list-head-title">
                    Referral List
                  </div>

                  <div
                  className={` flex-align-items-center ${isMobile?"":"d-flex"}` }
                  style={{ gap: "10px" ,width:`${isMobile?"inherit":""}`}}
                  >
                    <Input
                      value={search}
                      allowClear
                      onChange={(e) => setSearch(e.target.value)}
                      size="small"
                      prefix={<FiSearch size={14} />}
                      placeholder="search"
                    />
                    <div style={{display:"flex", gap:"5px",marginTop:`${isMobile?"10px":""}`}}>
                    <Button
                      icon={<AiOutlinePlusCircle size={18} className="mr-1" />}
                      onClick={() => {
                        // dispatch(
                        //   setServiceData({ ...initialServiceDetailval })
                        // );

                        const payload = {
                          selectLocationValue: "1",
                        };
                        
                        dispatch(
                          setServiceData({ 
                            ...initialServiceDetailval, // Spread the original object
                            ...payload // Override the specific key(s) with the new value(s)
                          })
                        );
                        navigation("/case-manager/request-service");
                        dispatch(updateServiceStep(serviceSteps.SELECT_USER));
                       }}
                      type="primary"
                    >
                      Add New Referral
                    </Button>

                    <FilterOptionsDropdown
                      onExport={() => {
                        exportData();
                      }}
                      onApply={(
                        doctorid,
                        referralStatusId,
                        startdate,
                        enddate,
                        partnerId,
                        typeId
                      ) => {
                        setdoctorId(doctorid);
                        setReferralStatusId(referralStatusId);
                        setstartdate(startdate);
                        setenddate(enddate);
                        setservicePartnerId(partnerId);
                        setserviceTypeId(typeId);
                      }}
                      onReset={() => {
                        setdoctorId("");
                        setReferralStatusId("");
                        setstartdate("");
                        setenddate("");
                        setservicePartnerId("");
                        setserviceTypeId("");
                      }}
                      isFromIncomingOutgoing={false}
                    />

                    <Button
                      onClick={() => {
                        if (!isExporting) {
                          setIsExporting(true);
                          exportData();
                        }
                      }}
                      type="primary"
                    >
                      {isExporting ? "Exporting..." : "Export"}
                    </Button>
                    </div>
                  </div>
                </div>
              )}

              { (
                <div className="data-table-contain">
                  <Table
                    loading={appointment?.loading || false}
                    locale={{ emptyText: <NoData /> }}
                    pagination={{
                      responsive: true,
                      hideOnSinglePage: false,
                      onChange: setPage,
                      pageSize: limit ?? 20,
                      total: appointment?.count || 0,
                      current: page ?? 1,

                      showSizeChanger: false,
                      showTotal: (total, range) => (
                        <PaginationUI
                          total={total}
                          range={range}
                          limit={limit ?? 20}
                          onPageChange={setPage}
                          page={page ?? 1}
                          onLimitChange={setLimit}
                        />
                      ),
                    }}
                    columns={columns}
                    dataSource={appointment?.data ?? []}
                    onRow={(row) => ({
                      onClick: () => openAppointmentDetail(row),
                    })}
                    selectableRowsVisibleOnly
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        {isUpdate && (
          <UpdateOrder
            data={isUpdate}
            reloadData={fetchData}
            onClose={onCloseUpateOrder}
          />
        )}
        {isAttchments && (
          <AttachmentsView
            data={attachmentsData}
            onClose={onCloseAttachments}
          />
        )}
 




        {isPdf?.careplanlink && (
          <PdfViewer
            onClose={() => setPdf(null)}
            url={isPdf?.careplanlink}
            esignLink={isPdf?.careplansignlink}
          />
        )}
        {cancelData && (
          <CancelReferralModal
          row={cancelData}
          setData={setCancelData}
             onClose={()=> setCancelData(null)}
             isVisible={cancelData !== null}
             onSuccess={()=>{
              fetchData()

             }}
          />
        )}
      </CaseMangerWrapper>
    </div>
  );
};




export const CancelReferralModal = ({ row, isVisible, onClose, onSuccess }) => {
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const { token } = useSelector(({ user }) => user);
  const { user } = useSelector((state) => state.user);
   
  // API call to cancel referral
  async function cancelReferral() {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "cancel_order");
    formData.append("auterizetokenid", user?.id);
    formData.append("id", row?.order_id);
    formData.append("reason", reason);

    
    try {
      const res = await apihitCall(formData, token);

      if (res?.data?.status === 200) {
        message.success('Referral cancelled successfully');
        onSuccess(); // Trigger success callback
        onClose(); // Close modal
        setReason(''); // Reset reason field
      } else {
        message.error('Failed to cancel referral. Please try again.');
      }
    } catch (error) {
      message.error('An error occurred while cancelling referral.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleCancelReferral = () => {
    if (!reason) {
      message.warning('Please enter a reason for cancellation.');
      return;
    }
    cancelReferral();
  };

  return (
    <Modal
      title="Cancel Referral"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={loading}>
          Close
        </Button>,
        <Button
          key="confirm"
          type="primary"
          loading={loading}
          onClick={handleCancelReferral}
        >
          Cancel Referral
        </Button>,
      ]}
    >
      <Input.TextArea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder="Enter reason for cancellation..."
        rows={4}
        disabled={loading}
      />
    </Modal>
  );
};
