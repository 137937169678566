import React, { useState, useRef, useEffect } from 'react';
import { Modal, Input, Button, message } from 'antd';
import { UserOutlined, RobotOutlined, SendOutlined } from '@ant-design/icons';
import {  CloseOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { apihitCall } from '../../../service/authService';
import { useSelector } from 'react-redux';

function AllNotesChat({patientId,setAllChat}) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allApiDone, setAllApiDone] = useState(false);
  const [allResponses, setAllResponses] = useState({});


  const [apiKey, setApiKey] = useState('AIzaSyAE1z7awgVAVbbHQc7jROz27mDepwePZwI');
  const messagesEndRef = useRef(null);
       const { token } = useSelector(({ user }) => user);
       const { user } = useSelector((state) => state.user);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getNoteType = (tab) => {
    switch (tab) {
      case "physicalTherapy":
        return "1";
      case "rehabilitation":
        return "2";
      case "occupational":
        return "3";
      case "speech":
        return "4";
      default:
        return "";
    }
  };


  const fetchAllTabData = async () => {
    setAllApiDone(false)
    const tabs = [
      "history",
      "progress",
      "consultation",
      "treatment",
      "goals",
      "physicalTherapy",
      "rehabilitation",
      "occupational",
      "speech",
    ];
  
    
    const tempResponses = {};

    for (const tab of tabs) {
      let formData = new FormData();
      formData.append("patientid", patientId);
      formData.append("clinicid", user?.society_id);
      formData.append("auterizetokenid", user?.id);
      formData.append("patientId", patientId);
  
      switch (tab) {
        case "history":
        case "progress":
          formData.append("action", "get_patient_summary_text");
          formData.append("summaryname", tab === "history" ? "HP" : "PROG NOTE");
          break;
        case "consultation":
          formData.append("action", "get_patient_consultation_request_notes_text");
          break;
        case "treatment":
          formData.append("action", "get_patient_treatment_plan_text");
          break;
        case "goals":
          formData.append("action", "get_patient_goals_text");
          break;
        case "physicalTherapy":
        case "rehabilitation":
        case "occupational":
        case "speech":
          formData.append("action", "get_patient_ptot_note_text");
          formData.append("note_type", getNoteType(tab));
          break;
        default:
          break;
      }
  
      try {
        const res = await apihitCall(formData, token);
        const hasData = res?.data?.status === 200 && res?.data?.result?.summary_text;
        if(hasData) {
          tempResponses[tab] = {
         
          data: res?.data?.result?.pdf_text || "",
        };
      }
      } catch (error) {
        console.error(`Error fetching data for tab ${tab}:`, error);
       
      }
    }
    setAllResponses(tempResponses); // Update state

   
    setAllApiDone(true)

    return allResponses;
  };
  

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    fetchAllTabData();
  }, []);

 
  const streamMessage = async (content, key, onProgress) => {
    try {
      const response = await fetch(
        `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-pro:generateContent?key=${key}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            system_instruction: {
    parts:
      { "text": `You are MedsetGo Assistant, a virtual assistant designed to help doctors extract meaningful insights from patient data. Your primary task is to interpret and summarize patient information accurately. user will ask you questions you need to answer from this ${JSON.stringify(allResponses)}`}},
   
            contents: [
              {
                parts: [{ text: content }],
              },
            ],
            safetySettings: [
              {
                category: 'HARM_CATEGORY_DANGEROUS_CONTENT',
                threshold: 'BLOCK_ONLY_HIGH',
              },
            ],
            generationConfig: {
              temperature: 1.0,
              maxOutputTokens: 800,
              topP: 0.8,
              topK: 10,
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const text = data?.candidates?.[0]?.content?.parts?.[0]?.text || '';

      if (!text) {
        throw new Error('No text in response');
      }

      const words = text.split(' ');
      let fullText = '';

      for (const word of words) {
        fullText += `${word} `;
        onProgress(fullText);
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
    } catch (error) {
      console.error('Error streaming message:', error);
      throw error;
    }
  };

  const handleSendMessage = async (content) => {

    // console.log("sedning")
    // console.log(allResponses)
   

    const userMessage = { role: 'user', content };
    const assistantMessage = {
      role: 'assistant',
      content: '',
      isStreaming: true,
    };

    setMessages((prev) => [...prev, userMessage, assistantMessage]);
    setIsLoading(true);

    try {
      await streamMessage(content, apiKey, (progress) => {
        setMessages((prev) => {
          const newMessages = [...prev];
          newMessages[newMessages.length - 1] = {
            role: 'assistant',
            content: progress,
            isStreaming: true,
          };
          return newMessages;
        });
      });

      setMessages((prev) => {
        const newMessages = [...prev];
        newMessages[newMessages.length - 1] = {
          role: 'assistant',
          content: newMessages[newMessages.length - 1].content,
          isStreaming: false,
        };
        return newMessages;
      });
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to send message. Please check your API key and try again.');
      setMessages((prev) => prev.slice(0, -1));
    } finally {
      setIsLoading(false);
    }
  };


  
    const ChatMessage = ({ message }) => {
      const isUser = message.role === 'user';
  
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: isUser ? 'row-reverse' : 'row',
            alignItems: 'flex-start',
            gap: '1rem',
          }}
        >
          <div
            style={{
              padding: '0.5rem',
              borderRadius: '50%',
              backgroundColor: isUser ? '#4299e1' : '#718096',
            }}
          >
            {isUser ? (
              <UserOutlined style={{ color: 'white' }} />
            ) : (
              <RobotOutlined style={{ color: 'white' }} />
            )}

            {allApiDone === false? "Please wait":""}
          </div>
          <div
            style={{
              flex: 1,
              padding: '0.5rem 1rem',
              borderRadius: '0.5rem',
              backgroundColor: isUser ? '#ebf8ff' : '#f7fafc',
            }}
          >
            <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>
              {message.content}
              {message.isStreaming && (
                <span
                  style={{
                    display: 'inline-block',
                    width: '0.5rem',
                    height: '1rem',
                    marginLeft: '0.25rem',
                    backgroundColor: '#718096',
                    animation: 'pulse 1.5s infinite',
                  }}
                />
              )}
            </p>
          </div>
        </div>
      );
    };
  
    const ChatInput = ({ onSendMessage, disabled }) => {
      const [message, setMessage] = useState('');
  
      const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim() && !disabled) {
          onSendMessage(message);
          setMessage('');
        }
      };
  
      return (
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            gap: '0.5rem',
          }}
        >
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your question..."
            disabled={disabled}
            style={{ flex: 1 }}
          />
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled || !message.trim()}
            icon={<SendOutlined />}
          />
        </form>
      );
    };
  
    return (
      <div
        style={{
          minHeight: '50vh',
          width:"500px",
         
          display: 'flex',
          position:"absolute",
          left:"0",
          bottom:"0",
          zIndex:"100",
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
     
  
        <div
          style={{
            maxWidth: '32rem',
            width: '70%',
            height:"500px",
            backgroundColor: 'white',
            borderRadius: '0.5rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
          }}
        >
         <div
  style={{
    backgroundColor: '#4299e1',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    position: 'relative', // For positioning the close icon
  }}
>
  <RobotOutlined style={{ color: 'white', fontSize: '1.5rem' }} />
  <h1 style={{ fontSize: '0.7rem', color: 'white' }}>
    AI Chat (All Notes)
  </h1>
  <CloseOutlined
    onClick={() => { 
        setAllChat(null)
     }}
    style={{
      color: 'white',
      fontSize: '1.5rem',
      position: 'absolute',
      top: '50%',
      right: '1rem',
      transform: 'translateY(-50%)', // Vertically center the close icon
      cursor: 'pointer',
    }}
  />
</div>
  
          <div
            style={{
              height: '45vh',
              overflowY: 'auto',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            {messages.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}
            <div ref={messagesEndRef} />
          </div>
  
          <div style={{ padding: '1rem', borderTop: '1px solid #e2e8f0' }}>
            {Object.keys(allResponses).length == 0?"Please wait":<ChatInput onSendMessage={handleSendMessage} disabled={isLoading} />}
          </div>
        </div>
      </div>
    );
  }
  
  export default AllNotesChat;
  