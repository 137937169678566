import { createSlice } from "@reduxjs/toolkit";
import { serviceSteps } from "../../utils/constants";
import {
  checkAvailability,
  deleteShiping,
  getOrderPrice,
  getUserAddress,
  getUserList,
} from "./actions";

export const initialServiceDetailval = {
  cpt:"",
  dx:"",
  address:"",
  appointmentid:"",
  shippingId:"",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  latitude: "",
  longitude: "",
  schedule_date: "",
  payment_amount: "",
  execution_date: "",
  timeSlot: "",
  timeslotid: "",
  device_type: "web",
  category_id: "",
  user_id: "",
  casemanager: "",
  payment_status: "cash",
  services: "",
  quantity: "1",
  OTHDec: "",
  country: "US",
  note: "",
  usershipping_id: "",
  visitid:"",
  
  is_fleet: "",
  isPharmacy: "0",
  vendorid: "",
  prescription_image: "",
  // prescription_image2: "",
  // prescription_image3: "",
  prescriptions: [],
  insuranceId: "",
  appointmentid: "",
  doctorFromId: "",
  doctorFromNPId:"",
  orderId: "",
  deliveryType: "0",
  facilityId: "0",
  selectLocationValue: "1",
  mriScanData: {},
  mrExtremity: {},
  mrAngiography: {},
  mrArthrography: {},
  ctScan: {},
  ctAngiography: {},
  dexa: {},
  ultraSound: {},
  xRay: {},
  nuclearMedicine: {},
  fluoroScopy: {},
  mri_scan:{},
  dmeCodesList: "",
  orderClassificationValue: '1',
  ultraSoundRadioValue: '1',
  partnerselection:"0"
};

const initialState = {
  users: {
    data: [],
    loading: true,
  },
  addressList: {
    loading: true,
    data: [],
  },
  subCategoryByCity: {},
  isAddnewAddress: false,
  currentStep: serviceSteps.SELECT_USER,
  serviceDetail: initialServiceDetailval,
  availability: {
    loading: true,
  },
  topCategories: {},
  drawerOpen: false,
  selectedDmeCode: [],
  exerciseTherapy: [],
  exerciseProtocols: [],
  userInsurance: [],
  isEditOrder: false,
  orderSuccess: false,
  placingOrder: false,
  servicePrice: [],
  locationType:"1"
};

export const service = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setServiceData: (state, action) => {
      state.serviceDetail = {
        ...state.serviceDetail,
        ...action.payload,
      };
    },
    setServiceLocationData: (state, action) => {
        
      state.locationType = action.payload;
    },
    toggleEditOrderDrawer: (state, action) => {
      state.isEditOrder = action.payload;
    },
    updateServiceStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setMainPatientTab: (state, action) => {
      state.mainActiveTab = action.payload;
    },
    toggleAddNewAdress: (state, action) => {
      state.isAddnewAddress = action.payload ?? false;
    },
    setTopCategories: (state, { payload }) => {
      state.topCategories = payload ?? [];
    },
    setSelectedDmeCode: (state, { payload }) => {
      state.selectedDmeCode = payload ?? [];
    },
    setDrawerOpen: (state, { payload }) => {
      state.drawerOpen = payload ?? false;
    },
    setExerciseTherapy: (state, { payload }) => {
      state.exerciseTherapy = payload ?? [];
    },
    setExerciseProtocals: (state, { payload }) => {
      state.exerciseProtocols = payload ?? [];
    },
    setUserInsurance: (state, { payload }) => {
      state.userInsurance = payload ?? [];
    },
    setSubCategoryByCity: (state, { payload }) => {
      state.subCategoryByCity = {
        ...(state.subCategoryByCity ?? {}),
        ...payload,
      };
    },
    UpdateplacingOrder: (state, action) => {
      state.placingOrder = action.payload ?? false;
    },
    OrderSuccess: (state, { payload }) => {
      state.orderSuccess = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state) => {
        state.users.loading = true;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.users = {
          data: action?.payload ?? [],
          loading: false,
        };
      })
      .addCase(checkAvailability.pending, (state, action) => {
        state.availability.loading = true;
      }) 
      .addCase(checkAvailability.fulfilled, (state, action) => {
        state.serviceDetail.vendorid = action.payload?.partnerId || "";
        state.availability = {
          loading: false,
          ...action.payload,
        };
      })
      .addCase(getUserAddress.pending, (state, action) => {
        state.addressList.data = [];
        state.addressList.loading = true;
      })
      .addCase(getUserAddress.fulfilled, (state, action) => {
        state.addressList.loading = false;
        state.addressList.data = action.payload;
      })

      .addCase(deleteShiping.fulfilled, (state, action) => {
        if (action.payload) {
          state.addressList.data = state.addressList?.data?.filter(
            (el) => el?.shippingId !== action?.payload
          );
        }
      })
      .addCase(getOrderPrice.fulfilled, (state, action) => {
        state.servicePrice = action.payload ?? [];
      });
  },
});

export const {
  setExerciseTherapy,
  setServiceData,
  setServiceLocationData,
  setTopCategories,
  toggleAddNewAdress,
  updateServiceStep,
  setExerciseProtocals,
  toggleEditOrderDrawer,
  setUserInsurance,
  setSubCategoryByCity,
  OrderSuccess,
  UpdateplacingOrder,
  setDrawerOpen,
  setSelectedDmeCode,
} = service.actions;

export default service.reducer;
